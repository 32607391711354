<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">支付宝分账用户列表</div>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-button type="primary" icon="el-icon-plus" @click="drawerVisible = true">添加分账账号</el-button>
          <el-button type="success" icon="el-icon-refresh" @click="
              p.page = 1;
              f5();
            ">刷新
          </el-button>
        </el-form-item>
        <br />
      </el-form>
      <div class="c-title">数据列表</div>
      <el-table :data="dataList" size="mini" :header-cell-style="{ 'text-align': 'center' }"
                :cell-style="{ 'text-align': 'center' }">
        <el-table-column label="编号" prop="id" width="50px"></el-table-column>
        <el-table-column v-if="this.sa_admin.role == 2" label="所属码队" prop="codeMchName"
                         width="100px"></el-table-column>
        <el-table-column label="分账姓名" prop="shareName" width="100px" />
        <el-table-column label="支付宝登录账号" prop="loginName" width="230px"></el-table-column>
        <el-table-column label="分账比例" prop="proportion" width="230px">
          <template slot-scope="s">
            <el-tag type="success" size="mini" effect="dark">{{ s.row.proportion }}%</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="分账场景" prop="scene" width="230px"></el-table-column>
        <el-table-column label="创建时间" prop="createdTime" width="200px"></el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="s">
            <el-button class="c-btn" type="danger" icon="el-icon-delete" @click="del(s.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes, jumper" :current-page.sync="p.page"
                       :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]"
                       @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>

      <el-drawer :append-to-body="true" :visible.sync="drawerVisible" size="35%" direction="rtl">
        <div>
          <el-form :model="m" label-width="120px" :rules="rules" ref="ruleForm">
            <el-form-item label="账号类型:" prop="type">
              <el-select v-model="m.type" style="width: 300px">
                <el-option label="支付宝账号" value="loginName" />
                <el-option label="uid" value="userId" />
              </el-select>
            </el-form-item>
            <el-form-item :label="m.type==='loginName'?'支付宝账号:':'uid'" prop="loginName">
              <el-input v-model="m.loginName" style="width: 300px" />
            </el-form-item>
            <el-form-item v-if="m.type==='loginName'" label="收款人姓名:" prop="shareName">
              <el-input v-model="m.shareName" style="width: 300px" />
            </el-form-item>
            <el-form-item label="商户选择:" placeholder="请选择" prop="appid">
              <el-select v-el-select-loadmore="loadmore" v-model="m.appid" @click.native="getZft()">
                <el-option v-for="item in zftlist" :key="item.appid+item.mchName" :label="parsAlipayConfigName(item)"
                           :value="item.appid" />
              </el-select>
            </el-form-item>
            <el-form-item label="分账比例:1-100" prop="proportion">
              <el-input type="number" v-model="m.proportion" style="width: 300px" />
            </el-form-item>

            <el-form-item label="分账场景:" prop="scene">
              <el-input v-model="m.scene" style="width: 300px" />
            </el-form-item>
            <el-form-item>
              <span class="dialog-footer">
                <el-button @click="drawerVisible=false">取 消</el-button>
                <el-button type="primary" @click="add()">提 交</el-button>
              </span>
            </el-form-item>
          </el-form>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    var checkShareName = (rule, value, callback) => {
      if (this.m.type === 'userId') {
        return callback()
      }
      if (!this.m.shareName) {
        return callback(new Error('请添加真实姓名'))
      }
      callback()
    }
    return {
      m: {
        appid: '',
        loginName: '',
        shareName: '',
        proportion: '',
        scene: '',
        type: 'userId',
      },
      p: {
        mchId: null,
        page: 1,
        limit: 10,
        type: 2,
      },
      drawerVisible: false,
      dataCount: 0,
      dataList: [],
      zftlist: [],
      channelList: [
        { payType: '1', name: '预授权'},
        { payType: '3', name: '当面付'},
        { payType: '5', name: '手机网站'},
        { payType: '7', name: '手机网站-游戏'},
        { payType: '8', name: '手机网站-商城'},
        { payType: '9', name: '订单码支付'},
        { payType: '25', name: '当面付-游戏网站'},
        { payType: '26', name: '当面付-商城网站'},
        { payType: '28', name: '支付宝APP支付'},
      ],
      rules: {
        loginName: [
          { required: true, message: '请输入支付宝账号', trigger: 'blur' },
        ],
        shareName: [
          { validator: checkShareName, trigger: 'blur' },
        ], scene: [
          { required: true, message: '请输入分账场景', trigger: 'blur' },
        ],
        appid: [
          { required: true, message: '请选择分账商户', trigger: 'change' },
        ],
        proportion: [
          { required: true, message: '请填写分账比例', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        type: 'get', // 默认请求类型
      }
      this.sa.ajax(
        '/v1/alipay_profitshare_account/list',
        this.p,
        function (res) {
          this.dataList = res.data.records // 数据
          this.dataCount = res.data.total // 分页
        }.bind(this),
        defaultCfg,
      )
    },
    add: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      }
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.sa.ajax(
            '/v1/alipay_profitshare_account/add',
            this.m,
            function () {
              this.drawerVisible = false
              this.f5()
            }.bind(this),
            defaultCfg,
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })

    },
    del: function (data) {
      var defaultCfg = {
        type: 'get', // 默认请求类型
      }
      this.sa.ajax(
        '/v1/alipay_profitshare_account/delete/' + data.id,
        function () {
          //刷新数据
          this.f5()
        }.bind(this),
        defaultCfg,
      )
    },
    getZft: function () {
      var defaultCfg = {
        sleep: 50,
        isBody: true, //是否是请求体请求
        msg: null,
      }
      var p = {
        page: 0,
        type: [1, 2],
        payType: [0, 25, 26, 3, 5, 7, 8],
        limit: 30,
      }
      this.sa.ajax(
        '/v1/alipay/config/mch/query', p,
        function (res) {
          this.zftlist = res.data.records // 数据
        }.bind(this),
        defaultCfg,
      )
    },
    parsAlipayConfigName (config) {
      const payTypeName = this.channelList.find(item => item.payType == config.payType)
      return config.mchName + ':' + payTypeName?.name
    },
    loadmore () {
      this.page = this.page++
      this.getZft()
    },
  },
  created: function () {
    this.f5()
  },
  directives: {
    'el-select-loadmore': {
      bind (el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          '.el-select-dropdown .el-select-dropdown__wrap',
        )
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            binding.value()
          }
        })
      },
    },
  },
}
</script>

<style>
</style>
